// ANCHOR Graph State
import {
  useGraphNodeValue,
} from 'react-graph-state';
import {
  swr,
} from 'swr-graph-state';

// ANCHOR Dependencies
import { authStateNode } from './auth-state-node';

export const authTokenNode = swr<string | undefined | null>({
  key: 'authToken',
  setup: (context) => {
    const user = context.get(authStateNode);

    return async () => {
      if (user) {
        return user.getIdToken();
      }
      return user;
    };
  },
  freshAge: 0,
  revalidateOnFocus: true,
  revalidateOnVisibility: true,
  staleAge: 1000 * 60 * 60,
});

export function useAuthToken() {
  const value = useGraphNodeValue(authTokenNode.resource);
  if (value.status === 'pending') {
    return undefined;
  }
  if (value.status === 'failure') {
    throw value.data;
  }
  return value.data;
}
