import {
  ICpdUnitsRewardResult,
  IOtherRewardResult,
  IRankingRewardResult,
} from './reward';
import { ISignatoryResult } from './signatory';
import { ISpeakerResult } from './speaker';

export enum PublishStatus {
  DRAFT = 'draft',
  PUBLISHED = 'published',
  CLOSED = 'closed',
  UNLISTED = 'unlisted',
}

export type ApprovalStatus =
  | 'approved'
  | 'rejected'
  | 'pending';

export const PUBLISH_STATUS = {
  0: PublishStatus.DRAFT,
  1: PublishStatus.PUBLISHED,
  2: PublishStatus.CLOSED,
  3: PublishStatus.UNLISTED,
};

export interface ICourseDescription {
  id: string;
  description: string;
}

export interface ICourseResult {
  readonly id: string;
  readonly title: string;
  readonly description: string;
  readonly price: number;
  readonly courseDisplayPhotoUuid?: string;
  readonly coursePreviewVideoUrl?: string;
  readonly prcAccreditationNumber?: string;
  readonly publishStatus: PublishStatus;
  readonly isArchived: boolean;
  readonly isPinned: boolean;
  readonly displayStudents: boolean;
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly sellType: 'whole' | 'modular' | 'link';
  readonly consumablePeriod?: number;
  readonly requireSequentialProgress: boolean;
  readonly paymentLink?: string;
  readonly lyonCommunity?: string;
  readonly communityDescription?: string;

  // Generated
  readonly industries: string[];
  readonly moduleCount: number;
  readonly studentCount: number;
  readonly videoHours: number;

  // Relationships
  readonly objectives: ICourseDescription[];
  readonly gains: ICourseDescription[];
  readonly requirements: ICourseDescription[];
  readonly lecturers?: ISpeakerResult[];
  readonly providerId: string;
  readonly cpdUnitRewards?: ICpdUnitsRewardResult[];
  readonly rankingRewards?: IRankingRewardResult[];
  readonly otherRewards?: IOtherRewardResult[];
}

export interface ICourseRevenueResult {
  revenue: number;
}

export interface ICourseCertificateResult {
  readonly accreditationNumber?: string;
  readonly backgroundPhotoUuid?: string;
  readonly colorTheme?: string;
  readonly courseId: string;
  readonly createdAt: string;
  readonly dateHeld?: string;
  readonly hasLogo?: boolean;
  readonly hasProviderName?: boolean;
  readonly hasAwardsThis?: boolean;
  readonly hasToDivider?: boolean;
  readonly description?: string;
  readonly id: string;
  readonly providerId: string;
  readonly reward?: string;
  readonly type: string;
  readonly name?: string;
  readonly label?: string;
  readonly certificateSignatories: ICourseCertificateSignatoryResult[];
}

export interface ICourseCertificateSignatoryResult {
  readonly signatory: ISignatoryResult;
  readonly isSuperimposed: boolean;
  readonly id: string;
}

export interface ICourseCertificateUserResult {
  readonly id: string;
  readonly certificateTemplateId: string;
  readonly userName: string;
  readonly userId: string;
  readonly shortId: string;
  readonly dateDistributed: string;
}

export interface IUserCertificateResult extends ICourseCertificateUserResult {
  readonly certificateTemplate: ICourseCertificateResult;
}
