/**
 * Converts arrays of array into a single array. Mainly used in useSWRInfinite datas
 *
 * @param data An array of arrays
 */
export function parseInfiniteData<T>(
  data: (T[] | undefined)[],
): T[] {
  return data?.reduce((previousValue, currentValue) => (
    previousValue?.concat(currentValue as T[])
  ), []) as T[];
}
