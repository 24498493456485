export enum RewardCertificateType {
  Participation = 'participation',
  Attendance = 'attendance',
  Completion = 'completion',
}

export const REWARD_CERTIFICATE_TYPE = {
  0: RewardCertificateType.Participation,
  1: RewardCertificateType.Attendance,
  2: RewardCertificateType.Completion,
};

export interface ICpdUnitsRewardPayload {
  readonly units: number;
  readonly isSelfDirected: boolean;
  readonly rewardCertificateType: RewardCertificateType;
  readonly industry: string;
}

export interface IRankingRewardPayload {
  readonly days: number;
  readonly rewardCertificateType: RewardCertificateType;
  readonly type: RankingRewardType;
}

export interface IOtherRewardsPayload {
  readonly units: number;
  readonly rewardCertificateType: RewardCertificateType;
  readonly type: string;
}

export enum RankingRewardType {
  Local = 'local',
  International = 'international',
}

export const RANKING_REWARD_TYPE = {
  0: RankingRewardType.Local,
  1: RankingRewardType.International,
};
