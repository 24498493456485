// TODO Fix Type
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
// ANCHOR Query String
import { Course_Sell_Types_Enum as CourseSellType } from '@lyon/graphql/types';

// ANCHOR Axios
import { IS_REMOTE } from '../axios/graphql';
import { API_TYPE, POST, PUT } from '../axios/methods';
import { getKeyByValue } from '../extras/get-key-by-value';

// ANCHOR Utils
import { parseInfiniteData } from '../extras/parseInfiniteData';

// ANCHOR Payloads
import {
  IGetCoursePayload,
  IGetCoursesPayload,
  IUpdateCourseStatusPayload,
  IGetCourseRevenuePayload,
  ICreateCourseModalPayload,
  IUpdateCourseModalPayload,
  ICreateCourseLecturerPayload,
  ICreateSpeakerPayload,
  ICreateCourseDescriptionPayload,
  IUpdateCourseTabDescriptionPayload,
  IUpdateCourseLecturerPayload,
  IUpdateCourseCertificatePayload,
  ICreateCourseCertificatePayload,
  ICreateCertificateSignatoryPayload,
  IUpdateCertificateSignatory,
  IUpdateCourseStudentStatusPayload,
  IDuplicateCourseModalPayload,
  IUpdateCoursePinStatusPayload,
  ICreateCourseReminderPayload,
  IUpdateCoursePublishStatusPayload,
  IUpdateCourseCommunityPayload,
} from './payloads/course';
import {
  RANKING_REWARD_TYPE,
  REWARD_CERTIFICATE_TYPE,
} from './payloads/reward';

// ANCHOR Results
import {
  ICourseCertificateResult,
  ICourseResult,
  ICourseRevenueResult,
  PUBLISH_STATUS,
} from './results/course';
import { EnumNumberFour, EnumNumberThree, EnumNumberTwo } from './results/ondemand';
import { ICpdUnitsRewardResult, IOtherRewardResult, IRankingRewardResult } from './results/reward';

export async function getCourses(
  { page, take }: IGetCoursesPayload,
): Promise<ICourseResult[] | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetPublishedCourses({
    skip: ((page - 1) * take),
    take,
  });

  if (response.courses) {
    const result = response.courses;

    return result.map((course) => ({
      ...course,
      publishStatus: PUBLISH_STATUS[course.publishStatus as EnumNumberFour],
      moduleCount: course.moduleCount.aggregate?.count,
      industries: parseInfiniteData(
        course.courseRewards
          .map((reward) => reward.cpdUnitRewards
            .map((unit) => unit.industry)),
      ),
    }) as unknown as ICourseResult);
  }

  return undefined;
}

export async function getCourse(
  { courseId }: IGetCoursePayload,
  isServer?: boolean,
): Promise<ICourseResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(undefined, IS_REMOTE && isServer).GetCourseById({
    id: courseId,
  });

  if (response.course) {
    const result = response.course;

    return {
      // Base
      ...result,
      publishStatus: PUBLISH_STATUS[result.publishStatus as EnumNumberFour],
      // Generated
      industries: parseInfiniteData(
        result.courseRewards
          .map((reward) => reward.cpdUnitRewards
            .map((unit) => unit.industry)),
      ),
      videoHours: result.videoHour.aggregate?.count,
      moduleCount: result.moduleCount.aggregate?.count,
      studentCount: result.studentCount.aggregate?.count,

      // Relationships
      objectives: result.objectives.map((objective) => ({
        id: objective.id,
        description: objective.description,
      })),
      gains: result.gains.map((gain) => ({
        id: gain.id,
        description: gain.description,
      })),
      requirements: result.requirements.map((requirement) => ({
        id: requirement.id,
        description: requirement.description,
      })),
      lecturers: result.lecturers.map((lecturer) => lecturer.speaker),
      cpdUnitRewards: parseInfiniteData(
        result.courseRewards
          .map((reward) => reward.cpdUnitRewards
            .map((unit) => ({
              ...unit,
              parentRewardId: reward.id,
              rewardCertificateType: REWARD_CERTIFICATE_TYPE[
                reward.rewardCertificateType as EnumNumberThree
              ],
            }) as ICpdUnitsRewardResult)),
      ),
      rankingRewards: parseInfiniteData(
        result.courseRewards
          .map((reward) => reward.rankingRewards
            .map((rank) => ({
              ...rank,
              parentRewardId: reward.id,
              type: RANKING_REWARD_TYPE[rank.type as EnumNumberTwo],
              rewardCertificateType: REWARD_CERTIFICATE_TYPE[
                reward.rewardCertificateType as EnumNumberThree
              ],
            }) as IRankingRewardResult)),
      ),
      otherRewards: parseInfiniteData(
        result.courseRewards
          .map((reward) => reward.otherRewards
            .map((other) => ({
              ...other,
              parentRewardId: reward.id,
              rewardCertificateType: REWARD_CERTIFICATE_TYPE[
                reward.rewardCertificateType as EnumNumberThree
              ],
            }) as IOtherRewardResult)),
      ),
    } as ICourseResult;
  }

  return undefined;
}

export async function createCourse(
  { token, signatories, ...payload }: ICreateCourseModalPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const { course } = await sdk(token).CreateCourse({
    ...payload,
    displayStudents: true,
    courseSellType: payload.courseSellType as CourseSellType,
  });

  if (course) {
    await POST(`/provider/${course.providerId}/course/${String(course.id)}/checkout`, {}, {
      headers: {
        'Content-Type': 'application/json',
      },
    }, API_TYPE.Payment);

    if (signatories) {
      const courseSignatories = signatories.map((signatory) => ({
        signatory_id: signatory.id,
        course_id: course.id,
      }));

      await sdk(token).CreateCourseSignatories({
        objects: courseSignatories,
      });
    }
  }

  return course;
}

export async function duplicateCourse({
  token, signatories, gains, requirements, objectives,
  lecturers, id, ...payload
}: IDuplicateCourseModalPayload) {
  const { sdk } = await import('../axios/graphql');
  const { course } = await sdk(token).CreateCourse({
    ...payload,
    courseSellType: payload.courseSellType as CourseSellType,
  });

  try {
    if (course) {
      await POST(`/provider/${course.providerId}/course/${String(course.id)}/checkout`, {}, {
        headers: {
          'Content-Type': 'application/json',
        },
      }, API_TYPE.Payment);
      if (signatories) {
        const courseSignatories = signatories.map((signatory) => ({
          signatory_id: signatory.id,
          course_id: course.id,
        }));

        await Promise.all([
          ...objectives.map(async (objective) => {
            await sdk(token).CreateCourseObjective({
              description: objective.description,
              courseId: course.id,
            });
          }),
          ...requirements.map(async (objective) => {
            await sdk(token).CreateCourseRequirement({
              description: objective.description,
              courseId: course.id,
            });
          }),
          ...gains.map(async (objective) => {
            await sdk(token).CreateCourseGain({
              description: objective.description,
              courseId: course.id,
            });
          }),
        ]);

        if (lecturers) {
          await Promise.all(lecturers.map(async (lecturer) => {
            await sdk(token).CreateCourseLecturer({
              speakerId: lecturer.id,
              courseId: course.id,
            });
          }));
        }

        const { modules } = await sdk(token).GetCourseContent({
          id,
        });

        if (modules.length > 0) {
          await Promise.all(modules.map(async (module) => {
            const { courseModule } = await sdk(token).CreateCourseModule({
              title: module.title,
              description: module.description,
              id: course.id,
              requireSequentialProgress: module.requireSequentialProgress,
              price: module.price,
              consumablePeriod: module.consumablePeriod,
              order: module.order,
            });

            if (courseModule) {
              await Promise.all(module.moduleItems.map(async (item) => {
                const currentArticle = item.article[0];
                const currentVideo = item.video[0];
                const currentAssessment = item.assessment[0];
                const currentAttachment = item.pdf[0];
                const currentLiveSession = item.liveSession[0];
                const currentForm = item.form[0];

                switch (item.itemType) {
                  case 0:
                    await sdk(token).CreateCourseArticleDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      body: currentArticle?.body,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                    });
                    break;
                  case 1:
                    await sdk(token).CreateCourseVideoDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                      videoUrl: currentVideo?.videoUrl,
                      description: currentVideo?.description,
                      status: currentVideo?.status,
                      source: currentVideo?.source,
                    });
                    break;
                  case 2:
                    await sdk(token).CreateCourseAssessmentDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                      formId: currentAssessment?.formId,
                    });
                    break;
                  case 3:
                    await sdk(token).CreateCourseAttachmentDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                      url: currentAttachment?.url,
                    });
                    break;
                  case 4:
                    await sdk(token).CreateCourseLiveSessionDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                      description: currentLiveSession?.description,
                      sessionDate: currentLiveSession?.sessionDate,
                      timeStart: currentLiveSession?.timeStart,
                      timeEnd: currentLiveSession?.timeEnd,
                      streamingService: currentLiveSession?.streamingService,
                      attendanceEnabled: currentLiveSession?.attendanceEnabled,
                      customLink: currentLiveSession?.customLink,
                    });
                    break;
                  case 5:
                    await sdk(token).CreateCourseFormDuplicate({
                      moduleId: courseModule.id,
                      title: item.title,
                      isFree: item.isFree,
                      order: item.order,
                      isLocked: item.isLocked,
                      enableCommentSection: item.enableCommentSection,
                      formId: currentForm?.formId,
                    });
                    break;
                  default:
                    break;
                }
              }));
            }
          }));
        }

        await sdk(token).CreateCourseSignatories({
          objects: courseSignatories,
        });

        const { certificates } = await sdk(token).GetCourseCertificateByCourseId({
          courseID: id,
        });

        await Promise.all(
          certificates.map(async (certificate) => {
            await sdk(token).CreateCourseCertificate({
              courseId: course.id,
              providerId: course.providerId,
              accreditationNumber: certificate.accreditationNumber,
              dateHeld: certificate.dateHeld,
              description: certificate.description,
              reward: certificate.reward,
              type: certificate.type,
              name: certificate.name,
              hasLogo: certificate.hasLogo,
              hasProviderName: certificate.hasProviderName,
              hasAwardsThis: certificate.hasAwardsThis,
              hasToDivider: certificate.hasToDivider,
              label: certificate.label,
            });
          }),
        );
      }
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error);
    if (course) {
      await sdk(token).DeleteCourseById({
        id: course.id,
      });
    }
    throw new Error(error as string);
  }

  return course;
}

export async function updateCourse(
  { token, ...payload }: IUpdateCourseModalPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const { course } = await sdk(token).UpdateCourse({
    ...payload,
    courseSellType: payload.courseSellType as CourseSellType,
  });

  if (course) {
    await PUT(`/provider/${course.providerId}/course/${String(course.id)}/checkout`, {}, {
      headers: {
        'Content-Type': 'application/json',
      },
    }, API_TYPE.Payment);
  }

  return course;
}

export async function getCourseRevenueCount(
  { courseId, token }: IGetCourseRevenuePayload,
): Promise<ICourseRevenueResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).GetRevenueByCourseId({
    courseId,
  });

  if (response.order) {
    return {
      revenue: response.order.reduce((total, order) => (
        Number(total) + Number(order.totalPrice)
      ), 0),
    };
  }

  return undefined;
}

export async function draftCourse(
  { courseId, token }: IUpdateCourseStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCoursePublishStatus({
    id: courseId,
    status: 0,
  });

  return response.course;
}

export async function publishCourse(
  { courseId, token }: IUpdateCourseStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCoursePublishStatus({
    id: courseId,
    status: 1,
  });

  return response.course;
}

export async function closeCourse(
  { courseId, token }: IUpdateCourseStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCoursePublishStatus({
    id: courseId,
    status: 2,
  });

  return response.course;
}

export async function updateCoursePublishStatus(
  { courseId, status, token }: IUpdateCoursePublishStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCoursePublishStatus({
    id: courseId,
    status: Number(getKeyByValue(PUBLISH_STATUS, status)),
  });

  return response.course;
}

export async function archiveCourse(
  { courseId, token }: IUpdateCourseStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCourseArchiveStatus({
    id: courseId,
    status: true,
  });

  return response.course;
}

export async function unarchiveCourse(
  { courseId, token }: IUpdateCourseStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCourseArchiveStatus({
    id: Number(courseId),
    status: false,
  });

  return response.course;
}

export async function updateCoursePinnedStatus(
  { courseId, token, isPinned }: IUpdateCoursePinStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).UpdateCoursePinnedStatus({
    id: courseId,
    isPinned,
  });

  return response.course;
}

export async function displayStudentsCourse(
  { courseId, token, displayStudents }: IUpdateCourseStudentStatusPayload,
) {
  const { sdk } = await import('../axios/graphql');
  const { course } = await sdk(token).UpdateCourseShowStudents({
    id: courseId,
    displayStudents,
  });

  return course;
}

export async function createSpeaker({
  token, ...payload
}: ICreateSpeakerPayload) {
  const { sdk } = await import('../axios/graphql');

  const { speaker } = await sdk(token).CreateSpeaker({
    name: payload.displayName,
    description: payload.description,
    displayPhotoUuid: payload.displayPhotoUuid,
    userId: payload.userId,
  });

  return speaker;
}

export async function createCourseLecturer({
  courseId, token, speakerId,
}: ICreateCourseLecturerPayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseLecturer } = await sdk(token).CreateCourseLecturer({
    speakerId,
    courseId,
  });

  return courseLecturer;
}

export async function createCourseObjective({
  courseId, token, description,
}: ICreateCourseDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { objective } = await sdk(token).CreateCourseObjective({
    description,
    courseId,
  });

  return objective;
}

export async function createCourseGain({
  courseId, token, description,
}: ICreateCourseDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { gain } = await sdk(token).CreateCourseGain({
    description,
    courseId,
  });

  return gain;
}

export async function createCourseRequirement({
  courseId, token, description,
}: ICreateCourseDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { requirement } = await sdk(token).CreateCourseRequirement({
    description,
    courseId,
  });

  return requirement;
}

export async function createCourseReminder({
  courseId, token, ...payload
}: ICreateCourseReminderPayload) {
  const { sdk } = await import('../axios/graphql');
  const { reminder } = await sdk(token).CreateCourseReminder({
    courseId,
    ...payload,
  });

  return reminder;
}

export async function deleteCourseLecturerById({
  token, courseId, speakerId,
}: IUpdateCourseLecturerPayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseLecturer } = await sdk(token).DeleteCourseLecturerById({
    courseId,
    speakerId,
  });

  return courseLecturer;
}

export async function deleteCourseObjectiveById({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseObjective } = await sdk(token).DeleteCourseObjectiveById({
    id,
  });

  return courseObjective;
}

export async function deleteCourseRequirementById({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseRequirement } = await sdk(token).DeleteCourseRequirementById({
    id,
  });

  return courseRequirement;
}

export async function deleteCourseGainById({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseGain } = await sdk(token).DeleteCourseGainById({
    id,
  });

  return courseGain;
}

export async function getCourseCertificates({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificates } = await sdk(token).GetCourseCertificateByCourseId({
    courseID: id,
  });

  return certificates as ICourseCertificateResult[];
}

export async function getCourseCertificateById({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificate } = await sdk(token).GetCourseCertificateById({
    id,
  });

  if (certificate) {
    return certificate as ICourseCertificateResult;
  }

  return undefined;
}

export async function createCourseCertificate({
  token, ...payload
}: ICreateCourseCertificatePayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificate } = await sdk(token).CreateCourseCertificate({
    ...payload,
  });

  return certificate;
}

export async function updateCourseCertificate({
  token, ...payload
}: IUpdateCourseCertificatePayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificate } = await sdk(token).UpdateCourseCertificate({
    ...payload,
  });

  return certificate;
}

export async function deleteCourseCertificate({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificate } = await sdk(token).DeleteCourseCertificateById({
    id,
  });

  return certificate;
}

export async function createCertificateSignatory({
  token, ...payload
}: ICreateCertificateSignatoryPayload) {
  const { sdk } = await import('../axios/graphql');
  const { certificateSignatory } = await sdk(token).CreateCertificateSignatories({
    ...payload,
    isSuperimposed: false,
  });

  return certificateSignatory;
}

export async function updateCertificateSignatory({
  token, ...payload
}: IUpdateCertificateSignatory) {
  const { sdk } = await import('../axios/graphql');
  const { certificateSignatory } = await sdk(token).UpdateCertificateSignatoriesPosition({
    ...payload,
  });

  return certificateSignatory;
}

export async function deleteCertificateSignatory({
  token, id,
}: IUpdateCourseTabDescriptionPayload) {
  const { sdk } = await import('../axios/graphql');
  const { signatory } = await sdk(token).DeleteCertificateSignatoryById({
    id,
  });

  return signatory;
}

export async function updateCourseCommunity({
  courseId, token, description, community,
}: IUpdateCourseCommunityPayload) {
  const { sdk } = await import('../axios/graphql');
  const { course } = await sdk(token).UpdateCourseCommunity({
    community,
    description,
    id: courseId,
  });

  return course;
}
