// TODO Fix Type
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable @typescript-eslint/no-unsafe-call */
import { IS_REMOTE } from '../axios/graphql';

// ANCHOR Payloads
import {
  IGetProviderPayload,
  IGetProviderRightsPayload,
  IGetProviderSignatories,
  IUpdateProviderInfoPayload,
  ICreateSignatoryPayload,
  IUpdateSignatoryBasePayload,
  ICreateManagingUserPayload,
  ICreateProviderPayload,
  IDuplicateProviderPayload,
} from './payloads/provider';
import { IUpdateCourseVideoPreviewPayload } from './payloads/course';

// ANCHOR Results
import {
  IProviderManagingUserResult,
  IProviderResult,
  IProviderRightsResult,
} from './results/provider';
import { ICourseResult, PUBLISH_STATUS } from './results/course';
import { ISignatoryResult } from './results/signatory';
import { EnumNumberFour } from './results/ondemand';
import { parseInfiniteData } from '../extras/parseInfiniteData';
import { ICourseDiscountPayload, ICourseDiscountUsage, ICreateCourseDiscountPayload } from './payloads/course-payment';
import { duplicateCourse } from './course';
import { ISpeakerResult } from './results/speaker';

export async function getProvider(
  { providerId }: IGetProviderPayload,
  isServer?: boolean,
): Promise<IProviderResult | undefined> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(undefined, IS_REMOTE && isServer).GetProvider({
    id: providerId,
  });

  if (response.provider.length) {
    const {
      subscribersAggregate,
      eventsAggregate,
      upcomingEventsAggregate,
      archivedEventsAggregate,
      industries,
      ...result
    } = response.provider[0];

    // TODO fix types
    return {
      ...result,
      subscribersCount: subscribersAggregate.aggregate?.count,
      eventsCount: eventsAggregate.aggregate?.count,
      upcomingEventsCount: upcomingEventsAggregate.aggregate?.count,
      archivedEventsCount: archivedEventsAggregate.aggregate?.count,
      industries: industries.map((industry) => industry.industryName),
    } as IProviderResult;
  }

  return undefined;
}

export async function getProviderByName(name: string): Promise<string[]> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetProviderByName({
    name,
  });

  return response.provider.map((item) => item.id);
}

export async function getProviderCourses(
  id: string,
  page?: number,
  take?: number,
) {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk().GetProviderCourses({
    id,
    skip: (page && take) ? ((page - 1) * take) : undefined,
    take,
  });

  if (response.courses) {
    const result = response.courses;

    return result.map((course) => ({
      ...course,
      publishStatus: PUBLISH_STATUS[course.publishStatus as EnumNumberFour],
      moduleCount: course.moduleCount.aggregate?.count,
      videoHours: course.videoHour.aggregate?.count,
      industries: parseInfiniteData(
        course.courseRewards
          .map((reward) => reward.cpdUnitRewards
            .map((unit) => unit.industry)),
      ),
    }) as unknown as ICourseResult);
  }

  return [];
}

export async function createProvider(
  { token, name }: ICreateProviderPayload,
) {
  const { sdk } = await import('../axios/graphql');

  const { provider } = await sdk(token).CreateProvider({
    name,
    description: `${name}'s learning space`,
  });

  return provider?.id as string | undefined;
}

export async function getProviderManagingUsers(
  { providerId, token }: IGetProviderSignatories,
): Promise<IProviderManagingUserResult[] | undefined> {
  const { sdk } = await import('../axios/graphql');
  const { managingUsers } = await sdk(token).GetProviderManagingUsersById({
    id: providerId,
  });

  return managingUsers.map((users) => users.user) as IProviderManagingUserResult[];
}

export async function createProviderManagingUser(
  { token, ...payload }: ICreateManagingUserPayload,
) {
  const { sdk } = await import('../axios/graphql');

  await sdk(token).CreateManagingUser({
    ...payload,
  });
}

export async function deleteProviderManagingUserById({
  token, ...payload
}: IGetProviderRightsPayload) {
  const { sdk } = await import('../axios/graphql');
  const { managingUser } = await sdk(token).DeleteManagingUserById({
    ...payload,
  });

  return managingUser;
}

export async function getProviderSignatories(
  { providerId, token }: IGetProviderSignatories,
): Promise<ISignatoryResult[] | undefined> {
  const { sdk } = await import('../axios/graphql');
  const { signatories } = await sdk(token).GetProviderSignatories({
    id: providerId,
  });

  return signatories as ISignatoryResult[];
}

export async function createProviderSignatory(
  { token, ...payload }: ICreateSignatoryPayload,
) {
  const { sdk } = await import('../axios/graphql');

  await sdk(token).CreateSignatory({
    ...payload,
  });
}

export async function deleteProviderSignatoryById({
  token, id,
}: IUpdateSignatoryBasePayload) {
  const { sdk } = await import('../axios/graphql');
  const { signatory } = await sdk(token).DeleteSignatoryById({
    id,
  });

  return signatory;
}

export async function getProviderRights(
  { providerId, token, userId }: IGetProviderRightsPayload,
): Promise<IProviderRightsResult> {
  const { sdk } = await import('../axios/graphql');
  const response = await sdk(token).GetProviderManagingUsersById({
    id: providerId,
  });

  return !!response.managingUsers.find((managingUser) => managingUser.user.id === userId);
}

export async function updateCourseVideoPreview(
  { token, courseId, ...payload }: IUpdateCourseVideoPreviewPayload,
) {
  const { sdk } = await import('../axios/graphql');

  const result = await sdk(token).UpdateCourseVideoPreview({
    id: courseId,
    video: payload.coursePreviewVideoUrl,
  });

  return result;
}

export async function updateProviderInfo({
  token,
  providerId,
  ...data
}: IUpdateProviderInfoPayload) {
  const { sdk } = await import('../axios/graphql');

  const response = await sdk(token).UpdateProvider({
    id: providerId,
    ...data,
  });

  return response.update_provider_by_pk;
}

export async function getDiscountUsage(
  { id, token }: IUpdateSignatoryBasePayload,
): Promise<ICourseDiscountUsage[] | undefined> {
  const { sdk } = await import('../axios/graphql');
  const { discount } = await sdk(token).GetDiscountUserUsage({
    id,
  });

  if (!discount) {
    return undefined;
  }

  const userIDs = discount.courseOrder.map((order) => order.userId);

  const { user } = await sdk(token).GetBasicUserDetailsByIDs({
    ids: userIDs,
  });

  return user as ICourseDiscountUsage[];
}

export async function getProviderDiscounts(
  { providerId, token }: IGetProviderSignatories,
): Promise<ICourseDiscountPayload[] | undefined> {
  const { sdk } = await import('../axios/graphql');
  const { discount } = await sdk(token).GetProviderDiscounts({
    providerId,
  });

  return discount.map((item) => ({
    ...item,
    courseName: item.course?.title,
    usedCount: item.courseOrdersAggregate.aggregate?.count ?? 0,
  })) as ICourseDiscountPayload[];
}

export async function createProviderDiscount(
  { token, ...payload }: ICreateCourseDiscountPayload,
) {
  const { sdk } = await import('../axios/graphql');

  await sdk(token).CreateCourseDiscount({
    ...payload,
  });
}

export async function deleteProviderDiscountById({
  token, id,
}: IUpdateSignatoryBasePayload) {
  const { sdk } = await import('../axios/graphql');
  const { courseDiscount } = await sdk(token).DeleteCourseDiscountById({
    id,
  });

  return courseDiscount;
}

export async function duplicateProvider({
  token,
  id,
  name,
}: IDuplicateProviderPayload) {
  const { sdk } = await import('../axios/graphql');
  const { provider, courses } = await sdk(token).GetProviderForDuplicate({
    id,
    idString: id,
  });

  if (provider) {
    const { provider: newProvider } = await sdk(token).CreateProviderDuplicate({
      data: {
        name,
        address: provider.address,
        contactNumber: provider.contactNumber,
        coverPhotoUuid: provider.coverPhotoUuid,
        description: provider.description,
        displayPhotoUuid: provider.displayPhotoUuid,
        aboutVideoUuid: provider.aboutVideoUuid,
        email: provider.email,
        createdAt: provider.createdAt,
        isArchived: provider.isArchived,
        prcAccreditationNumber: provider.prcAccreditationNumber,
        website: provider.website,
        lyonCommunity: provider.lyonCommunity,
        certificateLogoUuid: provider.certificateLogoUuid,
        isLightTheme: provider.isLightTheme,

        certificate_signatories: {
          data: provider.signatories.map((signatory) => ({
            companyName: signatory.companyName,
            honorific: signatory.honorific,
            isActive: signatory.isActive,
            name: signatory.name,
            position: signatory.position,
            signatureImageUuid: signatory.signatureImageUuid,
            uploaderId: signatory.uploaderId,
          })),
        },

        provider_managing_users_users: {
          data: provider.managingUsers.map((manager) => ({
            userId: manager.userId,
          })),
        },
      },
    });

    if (newProvider && courses.length) {
      try {
        await Promise.all(
          courses.map(async (course) => (
            duplicateCourse({
              token,
              id: course.id,
              providerId: newProvider.id,
              title: course.title,
              description: course.description,
              price: course.price,
              courseDisplayPhotoUuid: course.course_display_photo_uuid || undefined,
              coursePreviewVideoUrl: course.course_preview_video_url || undefined,
              courseSellType: course.course_sell_type,
              consumablePeriod: course.consumable_period,
              requireSequentialProgress: course.require_sequential_progress,
              displayStudents: course.display_students,
              gains: course.gains.map((gain) => ({
                id: gain.id,
                description: gain.description,
              })),
              requirements: course.requirements.map((requirement) => ({
                id: requirement.id,
                description: requirement.description,
              })),
              objectives: course.objectives.map((objective) => ({
                id: objective.id,
                description: objective.description,
              })),
              lecturers: course.lecturers.map((lecturer) => {
                if (lecturer.speaker) {
                  return {
                    id: lecturer.speaker?.id,
                    description: lecturer.speaker?.description,
                    displayPhotoUuid: lecturer.speaker?.display_photo_uuid,
                    name: lecturer.speaker?.name,
                  };
                }

                return null;
              }).filter(Boolean) as ISpeakerResult[],
              signatories: newProvider.signatories.map((signatory) => ({
                id: signatory.id,
                name: signatory.name,
                position: signatory.position,
                signatureImageUuid: signatory.signatureImageUuid,
                companyName: signatory.companyName,
                honorific: signatory.honorific || undefined,
                isActive: signatory.isActive,
                uploaderId: signatory.uploaderId || undefined,
              })),
            })
          )),
        );
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
        await sdk(token).DeleteProviderById({
          id: newProvider.id,
        });
        throw new Error(error as string);
      }

      return newProvider;
    }

    return null;
  }

  return null;
}
